$(document).ready(function() {
  $('.group-manager-row').dblclick(function() {
    $("body").css("cursor", "wait");
    window.location = "/experiments/group_managers/" + $(this).data('group-manager-id') + "/participants";
  });

  $(".show_all_toggle").unbind("click").click(function() {
    let group_manager_id = $(this).data('group_manager-id');
    $(".show_all_toggle_" + group_manager_id).toggle();
    $(".show_all_" + group_manager_id).toggle();
  });
});
